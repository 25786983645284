
import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactModal from 'react-modal';
import Alert from 'react-bootstrap/Alert';
import io from 'socket.io-client';
import './App.css';

const axios = require('axios');

var socket = io('http://starcraftza.herokuapp.com');

class App extends Component{
  constructor() {
      super();
      this.state = {
        value: null,
        state: "animation",
        textValue:true,
        lightvalue1: true,
        lightvalue2: false,
        lightvalue3: false,
	      SignIn: true,
	      password: null,
        showError:false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
    if((event.target.value).lenght===0){
        this.setState({textValue:true});
    }else{
        this.setState({textValue:false});
    }
  }

  changePassword(event){
    this.setState({password: event.target.value});
  }

  changeRadio(event){
    this.setState({textValue: event.target.value});
    if(this.state.textValue===false){
      this.setState({textValue:true});
    }else{
      this.setState({value: null,textValue:false});
    }
  }

  componentDidMount(){
    socket.on('post', function(message){
      console.log(message);
    });
  }

  Post(){
    var stringValue="";

    if(this.state.textValue){
        this.setState({value:"default"});
        //console.log('default');
    }

    if(this.state.value==="default"){
      if(this.state.lightvalue1){
        socket.emit('post', {message:'default', ligth:1});
      }

      else if(this.state.lightvalue2){
        socket.emit('post', {message:'default', ligth:2});
      }

      else if(this.state.lightvalue3){
        socket.emit('post', {message:'default', ligth:3});
      }
    }

   else{
     if(this.state.lightvalue1){
       socket.emit('post', {message: this.state.value, ligth:1});
     }

     else if(this.state.lightvalue2){
       socket.emit('post', {message: this.state.value, ligth:2});
     }

     else if(this.state.lightvalue3){
       socket.emit('post', {message: this.state.value, ligth:3});
     }
   }
  }

  checkPassword(password){
      if(window.btoa(this.state.password)==="bWF5dGhlNHRoYmV3aXRoVSE="){
        this.Post();
        this.setState({showError: false, noticeMessage:"Post successful"});
      }else{
        this.setState({showError: true, noticeMessage:"Failed, Please try again"});
      }
  }

  clickedRadio1(){
     this.setState({lightvalue1: true,lightvalue2: false, lightvalue3: false});
  }

  clickedRadio2(){
     this.setState({lightvalue1: false,lightvalue2: true, lightvalue3: false});
  }

  clickedRadio3(){
     this.setState({lightvalue1: false, lightvalue2: false, lightvalue3: true});
  }

  render(){
    return (
    <div className="App">
      <div className='Nav'>
        <div>
          <Form.Label className='StarWarsFont'>Space Craft</Form.Label>
        </div>
      </div>
        <Container>
            <Form>
              <div className='Form'>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label className='StarWarsSecond'>Scrolling Text</Form.Label>
                    <Form.Control type="text" placeholder="Enter Text" onChange={this.handleChange}/>
                    <Form.Check type="checkbox"  name="text" value={this.state.textValue} label="Restore Default Text"  onChange={(value) => this.changeRadio(value)}/>
                </Form.Group>
              </div>

                <div className='Form'>
                  <Form.Group controlId="formBasicCheckbox">
                      <Form.Label className='StarWarsSecond'>Lighting</Form.Label>
                      <Form.Check type="checkbox" label="Animation" checked={this.state.lightvalue1} onChange={(value) => this.clickedRadio1(value)}/>
                      <Form.Check type="checkbox" label="All On"  checked={this.state.lightvalue2} onChange={(value) => this.clickedRadio2()}/>
                      <Form.Check type="checkbox" label="All Off"  checked={this.state.lightvalue3} onChange={(value) => this.clickedRadio3()}/>
                  </Form.Group>
                </div>

                <div className='Form'>
                  <Form.Group controlId="formBasicEmail">
                      <Form.Label className='StarWarsSecond'>Password</Form.Label>
                      <Form.Control type="password" name="password" placeholder="Enter Password" onChange={(value) => this.changePassword(value)}/>
                  </Form.Group>
                </div>

                <div className='Form'>
                <Row>
                  <Col>
                  { this.state.showError ?
                    (<Alert variant={"warning"}> {this.state.noticeMessage} </Alert>)
                    :
                    null
                  }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" onClick={() => this.checkPassword()} className='Button'>
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
        </Container>
    </div>
  );
  }
}

export default App;
